button {
   background-color: transparent; 
   border: none;
   cursor: pointer;
   padding: 10px;
}

button:hover {
    background-color: #f7fafadb;
}

.niceButton {
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: transparent;
    border: 4px solid #4c86a8;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    color: #4C86A8;
    cursor: pointer;
    padding: 15px;
    text-align: center;
}
  
.niceButton:hover {
    background-color: #4c86a8;
    color: white;
    border: 4px solid #4c86a8;
}

.niceButton:active {
    background-color: #3A6B87; /* Darker shade for active state */
    border: 4px solid #4c86a8;
    color: white;
    transform: scale(0.97); /* Slightly shrink the button */
    box-shadow: 6px 4px 2px rgba(0, 0, 0, 0.3); /* Add a shadow for depth */
}

.projectButton {
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: transparent;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    padding: 15px;
}

.projectButton:hover {
    background-color: #4c86a844;
    border: 4px solid #4c86a8;
}

.projectButton:active {
    border: 4px solid #4c86a8;
    background-color: #4c86a844;
    transform: scale(0.97); /* Slightly shrink the button */
    box-shadow: 6px 4px 2px rgba(0, 0, 0, 0.3); /* Add a shadow for depth */
}

.iconButton {
    width: '20px';
    height: '20px';
}