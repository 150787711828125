@import url('https://fonts.googleapis.com/css2?family=Playwrite+AU+VIC:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.font_fancy {
    font-family: 'Playwrite AU VIC';
}

h1 {
    font-size: 80px;
}

h2 {
    font-size: 42px;
    font-family: 'Roboto';
}

h3 {
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 500;
}
h4 {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
}

h5 {
    font-family: 'Roboto';
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: 600;
}

h6 {
    font-family: 'Montserrat';
    letter-spacing: 0px;
    font-size: 24px;
    font-weight: 400;
}

p {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 300;
}

button {
    font-family: 'Roboto';
    font-size: 24px;
}