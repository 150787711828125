* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}


@media screen and (min-width: 1260px) {
    .page {
        max-width: 1260px;
        margin-left: auto;
        margin-right: auto;
    }
}